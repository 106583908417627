import React from "react";

import { StarIcon } from "@heroicons/react/solid";
import { VideoCameraIcon } from "@heroicons/react/outline";
import WistiaVideo from "components/WistiaVideo";
import { HalfStarIcon, FullStarIcon } from "components/Graphics";

interface IReviewsProps {
  video_reviews: IVideoReviewsProps[];
  reviews: IReviewProps[];
}
export default (props: IReviewsProps) => (
  <>
    <div className="flex justify-center items-center space-x-2">
      <ReviewStars stars={4.5} size={7} />
      <p className="text-md font-[Montserrat]" children="Basado en 217 valoraciónes" />
    </div>

    <div className="sm:grid md:grid-cols-3">
      <div className="col-start-1">
        <VideoReview {...props.video_reviews[1]} /> {/* Carmen Lomana */}
        <Review {...props.reviews[1]} /> {/* Unknown */}
        <Review {...props.reviews[9]} /> {/* carmenmunozponce */}
        <Review {...props.reviews[4]} /> {/* Sara */}
      </div>
      <div className="col-start-2 ">
        <Review {...props.reviews[0]} /> {/* Carmen Muñoz */}
        <Review {...props.reviews[6]} /> {/* myriamlapique */}
        <Review {...props.reviews[7]} /> {/* rosapvila */}
        <Review {...props.reviews[8]} /> {/* Unknown */}
        <Review {...props.reviews[10]} /> {/* Unknown */}
        <Review {...props.reviews[2]} /> {/* Patrick */}
      </div>
      <div className="col-start-3">
        <VideoReview {...props.video_reviews[0]} /> {/* Zaida Miranda */}
        <Review {...props.reviews[11]} /> {/* cathyylopez */}
        <Review {...props.reviews[3]} /> {/* Laura */}
        <Review {...props.reviews[5]} /> {/* Paula V */}
      </div>
    </div>
  </>
);

const ReviewStars = ({ stars, size }: { stars: number; size: number }) => (
  <div className="flex items-center">
    {[...Array(Math.floor(stars))].map(() => (
      <StarIcon className={`h-${size} w-${size}`} />
    ))}
    {stars % 1 ? <HalfStarIcon className={`h-${size} w-${size}`} /> : null}
    {[...Array(Math.floor(5 - stars))].map(() => (
      <FullStarIcon className={`h-${size} w-${size} `} />
    ))}
  </div>
);

export interface IReviewProps {
  rating: number;
  title?: string;
  name: string;
  content: string;
}
const Review = ({ rating, title, name, content }: IReviewProps) => (
  <div className="border border-gray-300 rounded-lg p-4 bg-white mx-2 my-4 shadow">
    <ReviewStars stars={rating} size={4} />
    <h2 className="font-bold my-2 font-[Montserrat] text-[18px]" children={title} />
    <h3
      className="opacity-50 my-2 uppercase tracking-widest font-[Montserrat] text-[11px]"
      children={name}
    />
    <p className="text-sm my-2 font-['Source_Sans_Pro'] text-[17px]" children={content} />
  </div>
);

export interface IVideoReviewsProps extends IReviewProps {
  scriptCode: string;
}
const VideoReview = ({ scriptCode = "qql257stsx", rating, name, content }: IVideoReviewsProps) => (
  <div className="relative border border-gray-300 rounded-lg pt-4 pl-4 pb-6 pr-6 bg-white mx-2 my-4 shadow">
    <VideoCameraIcon className="h-4 w-4 absolute right-0 mr-2 bottom-0 mb-2" />
    <WistiaVideo scriptCode={scriptCode} />
    <div className="mt-4" />
    <ReviewStars stars={rating} size={4} />
    <h3
      className="opacity-50 my-2 uppercase tracking-widest font-[Montserrat] text-[11px]"
      children={name}
    />
    <p className="text-sm my-2 font-['Source_Sans_Pro'] text-[17px]" children={content} />
  </div>
);
