import React from "react";

export default function WistiaVideo({ scriptCode = "728ikbusni" }) {
  React.useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = `https://fast.wistia.com/embed/medias/${scriptCode}.jsonp`;
    script1.async = true;

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  });

  return (
    <>
      <div
        className={`wistia_embed wistia_async_${scriptCode} videoFoam=true object-contain`}
        style={{ position: "relative", height: "100%" }}
      ></div>
    </>
  );
}
