import React from "react";

import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Disclosure } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";

interface IIngredientsProps {
  ingredient_list: IIngredientProps[];
  image: {
    image: ImageDataLike;
    alt: string;
  };
}
export default function Ingredients(props: IIngredientsProps) {
  return (
    <div className="grid sm:grid-cols-2 gap-8">
      <IngredientsList list={props.ingredient_list} />
      <div>
        <GatsbyImage
          className="pt-12 px-10 w-full"
          image={getImage(props.image.image)!}
          alt={props.image.alt}
        />
      </div>
    </div>
  );
}

interface IIngredientProps {
  name: string;
  description: string;
}
const IngredientsList = ({ list }: { list: IIngredientProps[] }) => (
  <div className="mx-5 sm:mx-0">
    <dl className="divide-y divide-planasDark border-t border-b border-planasDark">
      {list.map((ingredient) => (
        <Disclosure as="div" key={ingredient.name}>
          {({ open }) => (
            <>
              <dt className="text-lg py-3">
                <Disclosure.Button className="text-left w-full flex justify-between items-start text-planasBlueDark">
                  <span
                    className="font-bold text-planasBlueDark ml-6"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {ingredient.name}
                  </span>
                  <span className="ml-6 mr-2 h-7 flex items-center opacity-70">
                    <PlusIcon
                      className={`h-6 w-6 transform ${
                        open ? "rotate-45" : "rotate-0"
                      } transition-all`}
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mb-2 pr-12">
                <p
                  className="text-base text-planasDark ml-6"
                  style={{ fontFamily: "Source Sans Pro" }}
                >
                  {ingredient.description}
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  </div>
);
