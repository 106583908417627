import React from "react";
import { Link } from "gatsby";

export interface IAboutSection {
  title: string;
  content: string;
  call_to_action: {
    title: string;
    link: string;
  };
  children: React.ReactNode;
}
export default (props: IAboutSection) => (
  <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8">
    <div className="mt-6 lg:col-span-5 lg:mt-0 ">
      <h1
        className="text-xl font-[Montserrat] font-bold tracking-wide opacity-80"
        children={props.title}
      />
      <p className="mt-1" children={props.content} />
      {/* <Link
        to={props.call_to_action.link}
        className="mt-4 py-1.5 px-4 inline-block text-center text-sm font-medium font-[Montserrat] text-white border border-black bg-black hover:text-black hover:bg-white transition-all"
        children={props.call_to_action.title}
      /> */}
      <div className="mt-4 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
        <div className="hover:shadow-xl">
          <Link
            to={props.call_to_action.link}
            className="flex w-full items-center justify-center border-transparent px-6 py-3 md:py-1.5 md:px-4 text-sm font-medium  hover:brightness-[1.75] font-[Montserrat] text-white border border-black bg-black hover:text-black hover:bg-white transition-all"
            children={props.call_to_action.title}
          />
        </div>
      </div>
    </div>
    <div className="flex-auto lg:col-span-7 ">
      <div className="aspect-[5/2] overflow-hidden">{props.children}</div>
    </div>
  </div>
);
