import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { QuoteIcon } from "components/Graphics";

interface IHeroProps {
  title: string;
  subtitle: string;
  call_to_action: {
    title: string;
    link: string;
  };
  review: {
    quote: string;
    user: string;
  };
  image: {
    image: ImageDataLike;
    alt: string;
  };
}

export default (props: IHeroProps) => (
  <div className="relative overflow-hidden bg-white max-w-7xl mx-auto">
    <div className="max-w-7xl mx-auto">
      <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-10 sm:pt-12 md:pt-16 lg:pt-20  xl:pt-28">
          <div className="sm:text-center lg:text-left">
            {/* Title */}
            {/* <h1
              className="text-4xl font-base tracking-wide text-gray-900 sm:text-5xl md:text-6xl font-[Montserrat]"
              children="CYBER MONDAY"
            /> */}
            <h1
              className="text-4xl font-base tracking-wide text-gray-900 sm:text-5xl md:text-6xl font-[Montserrat]"
              children={props.title}
            />
            {/* Subtitle */}
            {/* <p
              className="mt-3 font-bold text-gray-800/80 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0"
              children="Hasta el lunes 28 de noviembre - 23:59"
            /> */}
            <p
              className="mt-3 font-bold text-gray-800/80 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0"
              children={props.subtitle}
            />
            {/* Button */}
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="hover:shadow-xl">
                <Link
                  to="/productos/pack-antiaging"
                  className="flex w-full items-center justify-center border border-transparent bg-[#1d4aff] px-8 py-3 text-base font-medium text-white hover:brightness-[1.75]  md:py-4 md:px-10 md:text-lg font-[Montserrat]"
                  children="Empieza Hoy"
                />
              </div>
            </div>
            {/* Quote */}
            <div className="relative text-red-100 w-full h-[80px] md:h-0">
              <div className="mt-14 font-['Source_Sans_Pro'] text-base">
                <QuoteIcon className="absolute top-0 left-0 w-8 -translate-y-1/3 text-gray-300/70 z-[0]" />
                <div className="absolute z-[1] text-gray-800/70">
                  <p className="ml-6" children={props.review.quote} />
                  <p className="text-right italic text-sm z-20" children={props.review.user} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <GatsbyImage
        className="h-56 w-full object-contain sm:h-72 md:h-96 lg:h-full lg:w-full ml-10"
        alt={props.image.alt}
        image={getImage(props.image.image)!}
        imgClassName="object-contain"
      />
    </div>
  </div>
);
