import React from "react";

import { PlayIcon } from "@heroicons/react/solid";
import MasterclassPreviewMP4 from "../../static/masterclass_preview.mp4";
import MasterclassPreviewWEBM from "../../static/masterclass_preview.webm";
import { Link } from "gatsby";

interface IMasterclassCTAProps {
  heading: string;
  video: {
    scriptCode: string;
  };
}
export default (props: IMasterclassCTAProps) => (
  <div className="relative items-center lg:flex">
    <div className="bg-[#1d4aff] w-full lg:w-8/12 pl-20 pr-40 py-32 shadow-xl shadow-[#1d4aff]/50">
      <h1
        className="text-4xl text-white font-[Montserrat] tracking-widest"
        children={props.heading}
      />
    </div>

    <div className="w-full flex justify-center lg:w-4/12 relative h-auto">
      <Link to="/secretos">
        <div className="relative lg:absolute w-[90%] lg:w-auto z-10 lg:h-72 aspect-video right-0 top-0 -translate-y-20 lg:-translate-y-1/2 group">
          <div id="video">
            <div className="absolute flex items-center left-1/2 h-full">
              <PlayIcon className="h-24 text-white  transform -translate-x-1/2 stroke-black stroke-[0.2px] group-hover:text-[#1d4aff]/80 transition-all" />
            </div>
            <video width="100%" muted autoPlay loop>
              <source src={MasterclassPreviewMP4} type="video/mp4" />
              <source src={MasterclassPreviewWEBM} type="video/webm" />
            </video>
          </div>
        </div>
      </Link>
    </div>
  </div>
);
