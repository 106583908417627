import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export interface IMoneyBackGuaranteeProps {
  title: string;
  description: string;
}
export default (props: IMoneyBackGuaranteeProps) => (
  <>
    <div className="grid grid-cols-5 py-5 text-white items-center">
      <StaticImage
        className="col-span-2 mx-auto"
        imgClassName="sm:h-72 object-contain"
        src="../../static/planas-warranty.png"
        alt="Dr Planas Warranty"
        placeholder="blurred"
      />
      <div className="col-span-3">
        <h1 className="text-3xl sm:text-5xl font-bold font-[Montserrat]" children={props.title} />
        <p className="text-xl sm:text-3xl my-5 fotn-[Montserrat]" children={props.description} />
      </div>
    </div>
  </>
);
