import React from "react";
import { IconContext } from "react-icons";
import { FaLeaf, FaVial, FaTruckMoving, FaMedal } from "react-icons/fa";

export interface IIncentiveProps {
  title: string;
  description: string;
  Icon?: any;
}
export default (props: IIncentiveProps[]) => {
  const incentives: IIncentiveProps[] = [
    {
      Icon: FaLeaf,
      title: props[0].title,
      description: props[0].description,
    },
    {
      Icon: FaVial,
      title: props[1].title,
      description: props[1].description,
    },
    {
      Icon: FaTruckMoving,
      title: props[2].title,
      description: props[2].description,
    },
    {
      Icon: FaMedal,
      title: props[3].title,
      description: props[3].description,
    },
  ];

  return (
    <>
      <div className="max-w-sm mx-auto grid grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-4">
        {incentives.map((incentive) => (
          <Incentive {...incentive} />
        ))}
      </div>
    </>
  );
};

const Incentive = ({ Icon, title, description }: IIncentiveProps) => (
  <div key={title} className="text-center sm:flex sm:text-left lg:block lg:text-center">
    <div className="sm:flex-shrink-0">
      <IconContext.Provider value={{ color: "black", size: "2em" }}>
        <Icon className="mx-auto" />
      </IconContext.Provider>
    </div>
    <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
      <h3 className="font-bold tracking-wide font-[Montserrat]" children={title} />
      <p className="mt-2 text-sm font-['Source_Sans_Pro']" children={description} />
    </div>
  </div>
);
