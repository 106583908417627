import React from "react";

import {
  QuoteIcon,
  BazaarLogo,
  HolaLogo,
  DiezMinutosLogo,
  ForbesLogo,
  FueraDeSeriesLogo,
} from "components/Graphics";

const asSeenQuotes = [
  {
    icon: BazaarLogo,
    name: "Harper's Bazaar",
    quote:
      "La línea perfecta para quienes quieren una piel radiante sin tener que pasar por quirófano.",
  },
  {
    icon: HolaLogo,
    name: "¡Hola!",
    quote: "Me sorprendió muchísimo lo rápido que empecé a notar los resultados.",
  },
  {
    icon: DiezMinutosLogo,
    name: "Diez Minutos",
    quote:
      "Otro de los problemas de la piel bajo la mascarilla es el acné. Busca productos que además de efecto antiarrugas sean antimicrobianos, antiinflamatorios, humectantes y reparadores. como el Serum Antiarrugas del Dr Planas. ",
  },
  {
    icon: ForbesLogo,
    name: "Forbes",
    quote:
      "Jorge Planas lidera una revolución en la cosmética de lujo de la mano de potentes ingredientes naturales.",
  },
  {
    icon: FueraDeSeriesLogo,
    name: "Fuera de Serie",
    quote:
      "Una terapia facial antiaging creada para ser fácil de usar y formar parte de la rutina de belleza diaria de todos aquellos que quieran cuidar su piel.",
  },
];

const LogoBox = ({
  Logo,
  loc,
  setQuote,
  className,
  svgClassName,
}: {
  Logo: React.ComponentProps<"svg">;
  loc: number;
  setQuote: React.Dispatch<
    React.SetStateAction<{
      icon: any;
      name: string;
      quote: string;
    }>
  >;
  className: string;
  svgClassName: string;
}) => {
  return (
    <div
      className={`border border-transparent hover:border-black flex items-center hover:shadow-2xl transition-all ${className}`}
      onMouseOver={() => setQuote(asSeenQuotes[loc])}
    >
      <Logo className={svgClassName} />
    </div>
  );
};

export default () => {
  const [selectedQuote, setSelectedQuote] = React.useState(asSeenQuotes[0]);
  return (
    <section className="mb-20 mx-5 sm:mx-0">
      <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <LogoBox
            Logo={BazaarLogo}
            setQuote={setSelectedQuote}
            loc={0}
            className="py-4 px-5"
            svgClassName="h-10"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <LogoBox
            Logo={ForbesLogo}
            setQuote={setSelectedQuote}
            loc={3}
            className="py-4 px-5"
            svgClassName="h-10"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <LogoBox
            Logo={DiezMinutosLogo}
            setQuote={setSelectedQuote}
            loc={2}
            className="py-4 px-5"
            svgClassName="h-14"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
          <LogoBox
            Logo={HolaLogo}
            setQuote={setSelectedQuote}
            loc={1}
            className="py-4 px-5"
            svgClassName="h-14"
          />
        </div>
        <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
          <LogoBox
            Logo={FueraDeSeriesLogo}
            setQuote={setSelectedQuote}
            loc={4}
            className="py-3 px-0"
            svgClassName="h-10"
          />
        </div>
      </div>

      {/* The Box with the selected quote*/}
      <div className="text-planasDark mt-12">
        <QuoteIcon className="h-12 mx-auto" />
        <h3 className="text-center text-xl font-bold" style={{ fontFamily: "Montserrat" }}>
          {selectedQuote.name}
        </h3>
        <div className="relative text-center max-w-3xl mx-auto">
          <p className="text-lg italic absolute w-full" style={{ fontFamily: "Source Sans Pro" }}>
            {`"${selectedQuote.quote}"`}
          </p>
        </div>
      </div>
    </section>
  );
};
