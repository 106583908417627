import React from "react";
import { Disclosure } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";

interface IFAQProps {
  faqs: {
    question: string;
    answer: string;
  }[];
}
export default (props: IFAQProps) => (
  <div className="mx-5 sm:mx-0">
    <dl className="divide-y border-t border-b mt-12">
      {props.faqs.map((faq) => (
        <Disclosure as="div" key={faq.question}>
          {({ open }) => (
            <>
              <dt className="text-lg py-2">
                <Disclosure.Button className="text-left w-full flex justify-between items-start text-planasBlueDark">
                  <span className="font-bold text-planasBlueDark font-[Montserrat] text-[15px]">
                    {faq.question}
                  </span>
                  <span className="ml-6 mr-2 h-7 flex items-center opacity-70">
                    <PlusIcon
                      className={`h-5 w-5 transform ${
                        open ? "rotate-45" : "rotate-0"
                      } transition-all`}
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-1 mb-4 pr-12">
                <p className="text-base text-planasDark font-['Source_Sans_Pro']">{faq.answer}</p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  </div>
);
