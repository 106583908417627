import * as React from "react";

import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { IndexQuery } from "../@types/graphql-types";
import Hero from "components/Hero";
import HeroNew from "components/HeroNew";
import Incentives from "components/Incentives";
import About from "components/About";
import AboutNew from "components/AboutNew";
import AsSeenIn from "components/AsSeenIn";
import MoneyBackGuarantee from "components/MoneyBackGuarantee";
import Ingredients from "components/Ingredients";
import Reviews from "components/Reviews";
import FAQ from "components/FAQ";
import MasterclassCTA from "components/MasterclassCTA";
import SectionHeader from "components/SectionHeader";

export const query = graphql`
  query {
    allProductsYaml(
      filter: { available_for_sale: { eq: true } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          slug
          name
          featuredImageAlt
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    allPagesYaml(filter: { page: { eq: "index" } }) {
      nodes {
        hero {
          title
          subtitle
          call_to_action {
            title
            link
          }
          review {
            quote
            user
          }
          image {
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alt
          }
        }
        incentives {
          title
          description
        }
        about {
          header {
            heading
            subheading
          }
          content {
            title
            content
            call_to_action {
              link
              title
            }
          }
        }
        guarantee {
          title
          description
        }
        ingredients {
          header {
            heading
            subheading
          }
          content {
            ingredient_list {
              action
              description
              name
            }
            image {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alt
            }
          }
        }
        reviews {
          header {
            heading
            subheading
          }
          content {
            video_reviews {
              name
              rating
              title
              content
              scriptCode
            }
            reviews {
              name
              rating
              title
              content
            }
          }
        }
        masterclass_cta {
          header {
            heading
            subheading
          }
          content {
            heading
            video {
              scriptCode
            }
          }
        }
        faq {
          header {
            heading
            subheading
          }
          content {
            faqs {
              question
              answer
            }
          }
        }
      }
    }
    allMdx {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
interface IIndexProps {
  data: IndexQuery;
}
export default ({ data }: IIndexProps) => (
  <>
    <section id="HERO" className="bg-white">
      <HeroNew {...data.allPagesYaml.nodes[0].hero} />
      {/* <div className="max-w-6xl mx-auto">
        <Hero {...data.allPagesYaml.nodes[0].hero} />
      </div> */}
    </section>

    <section id="INCENTIVES" className="bg-blue-50">
      <div className="max-w-6xl mx-auto py-7 ">
        <Incentives {...data.allPagesYaml.nodes[0].incentives} />
      </div>
    </section>

    <section id="ABOUT" className="bg-white mt-28">
      <SectionHeader {...data.allPagesYaml.nodes[0].about.header} />
      <div className="max-w-6xl mx-auto space-y-7 mt-12 px-3">
        <AboutNew {...data.allPagesYaml.nodes[0].about.content[0]}>
          <StaticImage
            imgStyle={{ height: "auto", marginTop: "-2rem" }} // change image trim
            alt="Dr Planas con el pack infalible"
            src="../static/product-serum-planas.png"
          />
        </AboutNew>

        <AboutNew {...data.allPagesYaml.nodes[0].about.content[1]}>
          <div className="flex h-full items-center space-x-5">
            {data.allProductsYaml.edges.map(({ node }) => {
              if (
                node.slug == "pack-antiaging" ||
                node.slug == "crema-reparadora" ||
                node.slug == "serum-antiarrugas"
              )
                return (
                  <div className="w-1/3">
                    <Link
                      key={node.slug}
                      className="relative block group hover:shadow-lg hover:shadow-[#F6CA55]/25 hover:-translate-y-1 transition-all"
                      to={`/productos/${node.slug}`}
                    >
                      <div
                        className="hidden absolute group-hover:flex items-center justify-center  bg-black bg-opacity-[0.4] text-white font-medium  w-full h-10 z-10 text-sm bottom-0"
                        children={node.name}
                      />
                      <GatsbyImage
                        className="aspect-square"
                        alt={node.featuredImageAlt}
                        image={getImage(node.featuredImage)!}
                        key={node.slug}
                      />
                    </Link>
                  </div>
                );
            })}
          </div>
        </AboutNew>

        <div className="py-28">
          <AsSeenIn />
        </div>
      </div>
    </section>

    <section id="GUARANTEE" className="bg-[#143352] py-2">
      <div className="max-w-6xl mx-auto">
        <MoneyBackGuarantee {...data.allPagesYaml.nodes[0].guarantee} />
      </div>
    </section>

    <section id="INGREDIENTS " className="bg-white py-28">
      <SectionHeader {...data.allPagesYaml.nodes[0].ingredients.header} />
      <div className="max-w-6xl mx-auto mt-12">
        <Ingredients {...data.allPagesYaml.nodes[0].ingredients.content} />
      </div>
    </section>

    <section id="REVIEWS" className="bg-gray-100 py-28">
      <SectionHeader {...data.allPagesYaml.nodes[0].reviews.header} />
      <div className="max-w-6xl mx-auto mt-12">
        <Reviews {...data.allPagesYaml.nodes[0].reviews.content} />
      </div>
    </section>

    {/* 1d4aff */}
    <section id="MASTERCLASS_CTA" className="bg-[#1d4aff] bg-opacity-20 pt-28 lg:pb-28">
      <SectionHeader {...data.allPagesYaml.nodes[0].masterclass_cta.header} />
      <div className="max-w-6xl mx-auto mt-12 ">
        <MasterclassCTA {...data.allPagesYaml.nodes[0].masterclass_cta.content} />
      </div>
    </section>

    <section id="FAQs" className="bg-gray-50 py-28">
      <SectionHeader {...data.allPagesYaml.nodes[0].faq.header} />
      <div className="max-w-3xl mx-auto mt-12">
        <FAQ {...data.allPagesYaml.nodes[0].faq.content} />
      </div>
    </section>
  </>
);
